<template>
    <div>
        <div class="card-tail" style="width:60%; padding:40px; height:700px">
            <div style="width: 100%;">
                <el-row>
                    <el-col :span="8">
                        <el-row style="margin-bottom:10px;">
                            <el-col :span="9">
                                <label style="font-size:15px">Kreditavgift i % (30 dagar)</label>
                            </el-col>
                            <el-col :span="6">
                                <el-input v-model="kreditavgift" @blur="saveChanges('kreditavgift')">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px;">
                            <el-col :span="9">
                                <label style="font-size:15px">Lägsta avgift</label>
                            </el-col>
                            <el-col :span="6">
                                <el-input v-model="lagstaAvgift" @blur="saveChanges('lagstaAvgift')">
                                    <template slot="append">kr</template>
                                </el-input>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px;">
                            <el-col :span="9">
                                <label style="font-size:15px">Fast avgift per faktura</label>
                            </el-col>
                            <el-col :span="6">
                                <el-input v-model="fastAvgift" @blur="saveChanges('fastAvgift')">
                                    <template slot="append">kr</template>
                                </el-input>
                            </el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="8">
                        <el-row style="margin-bottom:10px;">
                            <el-col :span="9">
                                <label style="font-size:15px">Max betalningsvillkor</label>
                            </el-col>
                            <el-col :span="6">
                                <el-input v-model="maxBetalningsvillkor" @blur="saveChanges('maxBetalningsvillkor')">
                                    <template slot="append">dagar</template>
                                </el-input>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px;">
                            <el-col :span="9">
                                <label style="font-size:15px">Maxbelopp för autogodkända</label>
                            </el-col>
                            <el-col :span="6">
                                <el-input v-model="maxBelopp" @blur="saveChanges('maxBelopp')">
                                    <template slot="append">kr</template>
                                </el-input>
                            </el-col>
                        </el-row>
                    </el-col>

                    <el-col :span="8" style="margin-bottom:70px;">
                        <el-row style="margin-bottom:10px;">
                            <el-col :span="9">
                                <label style="font-size:15px">Regress</label>
                            </el-col>
                            <el-col :span="6">
                                <el-switch v-model="regress" @change="saveSwitchChanges" size="medium" />
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px;">
                            <el-col :span="9">
                                <label style="font-size:15px">Antal dagar till återköp</label>
                            </el-col>
                            <el-col :span="6">
                                <el-input v-model="antalDagar" @blur="saveChanges('antalDagar')">
                                    <template slot="append">dagar</template>
                                </el-input>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px;">
                            <el-col :span="9">
                                <label style="font-size:15px">Dröjsmålsränta mån</label>
                            </el-col>
                            <el-col :span="6">
                                <el-input v-model="dröjsmål" @blur="saveChanges('dröjsmål')">
                                    <template slot="append">månader</template>
                                </el-input>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>

            </div>
        </div>
    </div>
</template>

<script>
import Api from "./factoring.api";
import { Switch } from "element-ui";


export default {
    data() {
        return {
            activeComponent: "ClientFactoringSettings",
            header: "",
            isSwitchOn: true,
            decisions: [],
            selectedFactoringDecisionId: null,
            kreditavgift: "",
            maxBetalningsvillkor: "",
            regress: false,
            lagstaAvgift: "",
            maxBelopp: "",
            antalDagar: "",
            fastAvgift: "",
            dröjsmål: "",
        };
    },

    components: {
        "el-switch": Switch,
    },

    watch: {
        $route: function() {
            this.activeComponent = this.$route.meta.activeTab;
            this.getClientHeader();
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Klienter");
    },

    async created() {
        this.getClientHeader();
        //await this.loadData();
        this.activeComponent = this.$route.meta.activeTab;
    },

    methods: {
        async getClientHeader() {
            this.header = await Api.getClientHeader(this.$route.params.clientId);
        },

        saveChanges(field) {
            console.log(`Saving changes for ${field}: ${this[field]}`);
        },
        saveSwitchChanges(newValue) {
            console.log("Switch changed:", newValue);
        },
    },
};
</script>

<style scoped>
/* Your component styles here */
</style>
